import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/authenticate/login/login.component';
import { authGuard } from './core/guards/auth.guard';
import { Roles } from './core/constants/Roles';

const routes: Routes = [
  {
    path: 'auth',
    component: LoginComponent
  },
  {
    path: 'EsqueciSenha',
    loadChildren: () => import('./pages/password/resetPassword/resetPassword.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'consultadocumento',
    loadChildren: () => import('./pages/certificate-validation/CheckCertificate/CheckCertificate.module').then(m => m.CheckCertificateModule)
  },
  {
    path: 'home',
    loadChildren: ()=> import('./pages/home/home.module').then(m => m.HomeModule),
    canMatch: [authGuard]
  },
  {
    path: 'Admin',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/student-portal/admin/admin.module').then(m => m.AdminModule),
        canActivate: [authGuard],
        data: {
          role: Roles.Admin
        }
      }
    ]
  },
  {
    path: 'Aluno',
    children: [
      {
        path: 'servicos',
        loadChildren: ()  => import('./pages/student-portal/service-options/service-options.module').then(m => m.ServiceOptionsModule),
        canMatch: [authGuard],
        data: {
          role: Roles.Student
        }  
      }
    ]
  },
  {
    path: 'Error',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule),
      }
    ]
  },
  {path: '', redirectTo: 'auth', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
