<mat-sidenav-container >
  <mat-sidenav
    #drawer
    class="sidenav"
    [ngClass]="{ hidden: !(isHandset$ | async) }"
    [fixedInViewport]="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <div class="flex">
      <mat-toolbar style="color: black;">Menu</mat-toolbar>
      <div class="m-auto mr-3">
        <mat-icon aria-label="Side nav toggle icon" (click)="drawer.close()">close</mat-icon>
      </div>
    </div>
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/Aluno/servicos']" (click)="drawer.close()">Serviços</a>
      <a  mat-list-item [routerLink]="['/Admin']" (click)="drawer.close()" style="color: wheat;" *ngIf="hasAdmin">Administrador</a>
    </mat-nav-list>
  </mat-sidenav>
  
  <mat-sidenav-content class="sidenav-container">
    <ng-content> </ng-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div *ngIf="(isHandset$ | async) === false">
        <a [routerLink]="['/Aluno/servicos']" >Serviços</a>
        <a  [routerLink]="['/Admin']" *ngIf="hasAdmin">Administrador</a>
      </div>
    </mat-toolbar>

  </mat-sidenav-content>
</mat-sidenav-container>
<!-- Add Content Here -->


