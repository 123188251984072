import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {

  studentName: string = this._auth.studentName;
  
  constructor(private _auth: AuthService) { }

  ngOnInit() {
  }

  logout() {
    this._auth.logout();
  }
}
