import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { AuthService } from "../authentication/auth.service";
import { Injectable } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = null;

    // Clonar a requisição original e substituir o cabeçalho de autorização
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`)
    });

    // Enviar a requisição clonada com o cabeçalho de autorização
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Redirecionar para a página de login
          //this.router.navigate(['/login']);
        } else if (error.status === 403) {
          // Exibir mensagem de acesso negado
          // Código para mostrar a mensagem
        }
        return throwError(error);
      })
    );
  }
}