import { Component, Inject } from '@angular/core';
import { AuthService } from './core/authentication/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sumep-portal';
  isAlive$ = this._auth.alive;

  constructor(public _auth: AuthService) {
  }

  get isAlive(): boolean {
    return localStorage.getItem('alive')?.valueOf() == 'oi' ? true : false
  }


}
