import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, map, of, take } from 'rxjs';
import { LoginRequest } from '../models/LoginRequest';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from '../services/sessionStorage.service';
import { jwtDecode } from "jwt-decode";
import { NgxSpinnerService } from 'ngx-spinner';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private islogin: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public  alive: Observable<boolean> = this.islogin.asObservable();
  private _decodedToken!: { [key: string]: string; };

  constructor(public _router: Router, private _http: HttpClient, private _snackBar: MatSnackBar,private _storageService: SessionStorageService, private _spinner: NgxSpinnerService)
  {
    this.loadDecodeToken();
  }

  private loadDecodeToken() {
    const token = this._storageService.getItem("secretSmp")
    if (token) {
      this._decodedToken = jwtDecode(token);
    }
  }

  public isAuthenticated(role: string): boolean {
    this.islogin.next(true);
  
    if (!this._decodedToken) {
      this._router.navigate(['/Error/session-expired']);
      return false;
    }
    if (this.isTokenExpired()) {
      this.islogin.next(false);
      this._router.navigate(['/Error/session-expired']);
      return false
    }
    if (!this.hasRole(role)) {
      this._router.navigate(['/Error/NotAuthorized']);
      return false
    }

    this.islogin.next(true);
    return true;
  }

  public isTokenExpired(): boolean {
    const currentDate: Date = new Date();
    const timeExpired = Number(this._decodedToken['exp']); // Timestamp em segundos
    return currentDate > new Date(timeExpired * 1000) ? true : false;
  }

  get studentName() {
    const decodedToken: any = jwtDecode(this._storageService.getItem("secretSmp"));
    const nameClaim: string[] = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
   
    return nameClaim[0];
  }

  get studentRA() {
    const decodedToken: any = jwtDecode(this._storageService.getItem("secretSmp"));
    const nameClaim: string[] = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
   
    return nameClaim[1];
  }

  public hasRole(role: string): boolean {
    const decodedToken: any = jwtDecode(this._storageService.getItem("secretSmp"));
    let rules = JSON.parse(decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
    return rules.some((x: string) => x === role)
  }

  login(authRequest: LoginRequest): Observable<any> {
    this._spinner.show();
    return this._http.post(`${environment.apiUrl}/Authentication`, authRequest, {responseType: 'text'}).pipe(
      map(response => {
        this._storageService.setItem("secretSmp", response)
        this.loadDecodeToken()
        this.islogin.next(true);
        this._router.navigate(['/Aluno/servicos']);
        this._spinner.hide();
      }),
      catchError((err) => {
        this.islogin.next(false);
        switch (err.error) {
          case "auth_user_not_exist":
            this.showMessage('Usuário não existe no sistema')
            break;
          case "auth_user_password_is_incorrect":
            this.showMessage("Senha incorreta!")
            break;
          default:
            this.showMessage("Sistema interno")
            break;
        }
        this._spinner.hide();

        return of(false);
      })
    )

  }

  logout() {
    this._spinner.show()
    setTimeout(() => {
      this.islogin.next(false);
      this._router.navigate(['/auth']);
      this._storageService.removeItem("secretSmp")
      this._spinner.hide()
    }, 6000)
  }

  hideBar() {
    this.islogin.next(false);
  }

  public showMessage(message: string) {
    this._snackBar.open(message, 'Fechar', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'mat-warn'
    });
  }

}


