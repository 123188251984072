<app-main-nav *ngIf="isAlive$ |async">
  <app-main-header> </app-main-header>
</app-main-nav>

<router-outlet></router-outlet>

<ngx-spinner ></ngx-spinner>
<ngx-spinner  size = "medium" color = "#ffffff" type = "ball-clip-rotate" [fullScreen] = "true"><p style="color: white" > Carregando... </p></ngx-spinner>
<!-- footer.component.html -->
<footer class="footer" *ngIf="isAlive$ |async">
  <div class="content">
    <span>Criado em 2024.</span>
      <span class="ml-3">
        Uma realização da gestão "Hélio Carlos Bonito"
      (2023-2024)
      </span>
  </div>
</footer>
