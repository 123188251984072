<header class="l-header">
  <div class="l-header-menu">
    <div class="l-header-menu-wrapper">
      <img
        class="header-logo"
        mat-card-image
        src="assets/sumepLogo.jpeg"
        alt="Photo of a Shiba Inu"
        style="width: 96px; border-radius: 50%"
      />

      <button mat-button color="warn"  class="flex btn text-white  header-link" (click)="logout()">
        <mat-icon>power_settings_new</mat-icon>
        Sair
      </button>
    </div>
  </div>
  <div class="header-wrapper">
    <div class="l-header-box">
      <h1 class="header-title">Olá, {{ studentName }}.</h1>
      <p class="header-subtitle">Seja bem-vindo ao Portal do Aluno.</p>
    </div>
  </div>
</header>
