import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { LoginRequest } from 'src/app/core/models/LoginRequest';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  loginForm!: FormGroup;

  constructor(public _auth: AuthService, private _fb: FormBuilder, private spinner: NgxSpinnerService, public _router: Router) { }

  ngOnInit() {
    this.createForm();  
  }

  login() {
    if (this.loginForm.valid) {
      this._auth.login(this.loginRequest).subscribe();
    }
    /**/
  }

  createForm() {
   this.loginForm = this._fb.group({
      ra: new FormControl("", {validators: [Validators.required], nonNullable: false}),
      password: new FormControl('', {validators: [Validators.required], nonNullable: true}),
    })
  }

  get loginRequest() {
    return this.loginForm.getRawValue() as LoginRequest
  }
  forgetPassword() {
    this._router.navigate(['/EsqueciSenha']);

  }
}
