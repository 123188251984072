<div
  class="login-wrapper"
  fxLayout="row"
  fxLayoutAlign="center center"
  style="background-color: #003152 !important"
>
  <mat-card>
    <div class="text-center m-2">
      <img
        mat-card-image
        src="assets/sumepLogo.jpeg"
        alt="Photo of a Shiba Inu"
        style="width: 185px"
      />
    </div>
    <mat-card-header>
      <mat-card-title class="text-center"
        >Portal de certificados</mat-card-title
      >
    </mat-card-header>

    <form class="example-form" fxLayout="row wrap" [formGroup]="loginForm">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <mat-label>RA</mat-label>
          <input matInput placeholder="" formControlName="ra"/>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label>Senha</mat-label>
          <input matInput placeholder="" type="password" formControlName="password" />
        </mat-form-field>
        <button type="button" mat-raised-button color="primary" class="btn-block" (click)="login()" [disabled]="!loginForm.valid">
          Conectar
        </button>
        <div class="text-center mt-2">
          <a mat-button (click)="forgetPassword()">Esqueceu a senha?</a>
          <mat-divider class="mt-1"></mat-divider>
        </div>
      </mat-card-content>
    </form>
  </mat-card>
</div>
